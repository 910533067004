import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchQuestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/questions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourseQuestions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/questions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchQuestion(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/questions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateQuestion(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
            .put('/questions/' + id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addQuestion(ctx, questionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/questions', { questionData })
          .then(response => resolve(response))
          .catch(error => reject(error))

      })
    },
    deleteQuestion(ctx, noteId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/questions/' + noteId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
