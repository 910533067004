var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add New Note ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"English Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"English Title","label-for":"title-en"}},[_c('b-form-input',{attrs:{"id":"title-en","autofocus":"","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.noteData.title_en),callback:function ($$v) {_vm.$set(_vm.noteData, "title_en", $$v)},expression:"noteData.title_en"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Arabic Title","label-for":"title-ar"}},[_c('b-form-input',{attrs:{"id":"title-ar","autofocus":"","trim":""},model:{value:(_vm.noteData.title_ar),callback:function ($$v) {_vm.$set(_vm.noteData, "title_ar", $$v)},expression:"noteData.title_ar"}})],1),_c('b-form-group',{attrs:{"label":"Choose PDF Type","label-for":'pdf_type'}},[_c('validation-provider',{attrs:{"name":"PDF Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"name":'pdf_type',"state":errors.length > 0 ? false:null,"value":"1"},model:{value:(_vm.noteData.pdf_type),callback:function ($$v) {_vm.$set(_vm.noteData, "pdf_type", $$v)},expression:"noteData.pdf_type"}},[_vm._v(" Upload File ")]),_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"name":'pdf_type',"state":errors.length > 0 ? false:null,"value":"2"},model:{value:(_vm.noteData.pdf_type),callback:function ($$v) {_vm.$set(_vm.noteData, "pdf_type", $$v)},expression:"noteData.pdf_type"}},[_vm._v(" Insert URL ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.noteData.pdf_type == 1)?_c('b-form-group',{attrs:{"label":"Select Note PDF","label-for":"mc-is-active"}},[_c('validation-provider',{attrs:{"name":"Note PDF","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":'wildcard-video',"accept":"application/pdf"},on:{"change":function($event){return _vm.uploadPdf($event)}},model:{value:(_vm.noteData.file),callback:function ($$v) {_vm.$set(_vm.noteData, "file", $$v)},expression:"noteData.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.noteData.pdf_type == 2)?_c('b-form-group',{attrs:{"label":"Enter PDF URL","label-for":"mc-is-active"}},[_c('validation-provider',{attrs:{"name":"PDF URL","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"url","id":'wildcard'},model:{value:(_vm.noteData.path),callback:function ($$v) {_vm.$set(_vm.noteData, "path", $$v)},expression:"noteData.path"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.categoriesOptions.length > 0)?_c('validation-provider',{attrs:{"name":"Choose Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Choose Category","label-for":"category","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categoriesOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-role"},on:{"input":function (val) { return _vm.categoryChanged(val); }},model:{value:(_vm.noteData.category_id),callback:function ($$v) {_vm.$set(_vm.noteData, "category_id", $$v)},expression:"noteData.category_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.subCategoryOptions.length > 0)?_c('b-form-group',{attrs:{"label":"Select Sub Category","label-for":"mc-is-active"}},[_c('validation-provider',{attrs:{"name":"Sub Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.subCategoryOptions,"reduce":function (val) { return val.value; },"state":errors.length > 0 ? false:null},model:{value:(_vm.noteData.sub_category_id),callback:function ($$v) {_vm.$set(_vm.noteData, "sub_category_id", $$v)},expression:"noteData.sub_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Is Free Content","label-for":"landmark"}},[_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"name":'is_free_content_lectures',"value":"1"},model:{value:(_vm.noteData.is_free_content),callback:function ($$v) {_vm.$set(_vm.noteData, "is_free_content", $$v)},expression:"noteData.is_free_content"}},[_vm._v(" Yes ")]),_c('b-form-radio',{staticClass:"custom-control-primary",attrs:{"name":'is_free_content_lectures',"value":"0"},model:{value:(_vm.noteData.is_free_content),callback:function ($$v) {_vm.$set(_vm.noteData, "is_free_content", $$v)},expression:"noteData.is_free_content"}},[_vm._v(" No ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Add ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }